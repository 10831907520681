@import "./src/scss/deps";
@import "./src/scss/old-deps";
@import "./src/scss/live-event/limelight/mixins.scss";

.theme-btn {
	text-decoration: none;
}

.lemonade-text {
	color: transparent;

	span {
		background: var(--gradient-sunset);
		background-clip: text;
	}
}

.theme-btn,
button {
	&.no-style {
		background: none;
		border: none;
		outline: none;
		cursor: pointer;

		&:focus {
			box-shadow: none;
		}
	}

	&.no-padding {
		padding: 0;
	}

	&.no-margin {
		margin: 0;
	}

	padding: 16px 24px;
	font-family: $font-family-poppins;
	font-size: 14px;
	font-weight: 500;
	color: $light-gray;
	background-color: $border-gray;
	border: none;
	border-radius: 100px;
	outline: none;
	cursor: pointer;

	&.focus-visible {
		&:focus-visible {
			outline: auto;
		}
	}

	svg:not(:last-child) {
		margin-right: 8px;
	}

	&.primary {
		color: $dark-gray;
		background-color: $cyan;
		transition: background-color $hover-transition-duration ease-out;

		&:hover {
			background-color: $cyan-hover;
			transition: background-color $hover-transition-duration ease-in-out;
		}

		&:active {
			background-color: $cyan-active;
		}

		&:not(.small) {
			&:focus-visible {
				background-color: $cyan-focus;
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
				border: 2px solid $focus-border;
				border-radius: 100px;
				padding: 14px 22px;
			}
		}
	}

	&.lemonade {
		background: $bl-lemonade;
		color: $black;

		&:hover {
			background: $bl-lemonade-hover;
		}

		&.no-style {
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&.small {
		padding: 8px 16px;
		font-size: 13px;
		transition: background-color $hover-transition-duration ease-out;

		&:not(.primary) {
			&:hover {
				background-color: $gray-hover;
				transition: background-color $hover-transition-duration ease-in;
			}

			&:active {
				background-color: $gray-active;
			}

			&:focus-visible {
				box-sizing: border-box;
				-moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
				border: 2px solid $focus-border;
				border-radius: 100px;
				padding: 6px 14px;
			}
		}
	}

	&.x-small {
		padding: 3px 3px;
		font-size: 12px;
	}

	&.back {
		margin-left: 24px;
		padding: 11px 0;
		text-align: left;
		color: $cyan;
		background-color: transparent;
		transition: 150ms color;

		svg {
			position: relative;
			top: 1px;
			margin-right: 6px;

			path {
				transition: 150ms fill;
			}
		}

		&:hover {
			color: $white;

			svg {
				path {
					fill: $white;
				}
			}
		}
	}

	&.no-padding {
		padding: 0;
	}

	&.round {
		@include radius-full;
		width: 32px;
		height: 32px;
		padding: 0;
		transition: 150ms background-color;

		svg {
			pointer-events: auto;

			path {
				fill: $white;
			}
		}

		&:hover {
			background-color: lighten($border-gray, 10%);
		}
	}

	&.sharp {
		border-radius: 0;
	}

	&.soft-btn-radius {
		border-radius: 12px;
	}

	&.clear {
		margin: 0;
		padding: 0;
		background: transparent;
	}

	//was on all button elements, but was causing every single button in the UI to inherit this blue outline
	//if you want to make a button have this outline, add the .highlight class to it
	&.highlight:focus {
		box-shadow: 0 0 0 1px $cyan;
	}

	&.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.inherit-radius {
		border-radius: inherit;
	}

	&.inherit-dimensions {
		height: inherit;
		width: inherit;
		border-radius: inherit;
	}

	&.btn-left-align {
		justify-content: flex-start;
	}

	&.btn-text-left-align {
		text-align: left;
	}

	&.nowrap {
		white-space: nowrap;
	}

	&.space-between {
		justify-content: space-between;
	}

	&.link-btn {
		background-color: var(--accentColor, $blue);
		color: var(--buttonTextColor, var(--true-white));
		border-radius: 8px;
		padding: 4px 28px;
		font-size: 12px;
		line-height: 24px;
		font-weight: bold;
		text-transform: uppercase;
	}

	&.caution {
		background-color: $red;
		color: $white;
	}

	&.restore-icon {
		padding-left: 0;
	}
}

button.text-only-primary {
	color: $cyan;
	transition: (background-color, color) $hover-transition-duration;

	&:hover {
		color: $cyan-hover;
		transition: (background-color, color) $hover-transition-duration;
	}
}

.session-modal {
	.modal-body {
		overflow-y: visible !important;
	}
}

.broadcast-group {
	display: flex;
	justify-content: space-between;
}

.broadcast-date {
	width: 100%;
	margin: 0px 10px 0 0;

	&.date-range-wrapper {
		margin-bottom: 10px;

		.date-range-picker {
			margin: 10px 10px 0 0;
		}

		.datepicker {
			.react-datepicker__input-container {
				input[type="text"] {
					min-width: 175px;
				}

				button::after {
					background-color: $image-gray;
					border-color: $border-gray;
				}
			}
		}
	}

	&.inline {
		display: flex;
	}

	&.grid {
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 8px;
	}

	.datepicker {
		@include radius-full;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 12.5px 16px;
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		line-height: 20px;
		font-weight: normal;
		font-style: normal;
		color: $white;
		background: transparent;
		border: 1px solid $border-light-gray;
		outline: none;
		transition: 150ms border;
		cursor: pointer;

		&:hover {
			border: 1px solid $gray-400;
		}

		.react-datepicker__navigation--next--with-time {
			margin-right: 35px;
		}

		.react-datepicker__input-container input[type="text"] {
			font-family: "Roboto", sans-serif;
			font-size: 14px;
			font-weight: 400;
			color: $white;
			background-color: transparent;
			border: none;
			user-select: none;
			width: 166px;

			&:active,
			&:focus {
				outline: none;
			}
		}

		.react-datepicker,
		.react-datepicker__header,
		.react-datepicker__time {
			font-family: "Roboto", sans-serif;
			font-size: 14px;
			font-weight: 400;
			background-color: $image-gray;
			border: none;
		}

		.react-datepicker__triangle {
			border-top-color: $image-gray;
		}

		.react-datepicker__current-month,
		.react-datepicker__navigation,
		.react-datepicker__header--time {
			margin-bottom: 24px;
			font-size: 18px;
			line-height: 21px;
			font-weight: bold;
		}

		.react-datepicker-time__header {
			font-size: 18px;
			line-height: 21px;
			font-weight: bold;
		}

		.react-datepicker__time-list {
			height: 300px !important;
		}

		.react-datepicker__navigation,
		.react-datepicker__header--time {
			margin-top: 28px;
		}

		.react-datepicker__navigation--next {
			margin-right: 72px;
		}

		.react-datepicker__navigation--previous {
			margin-left: 36px;
		}

		.react-datepicker__current-month,
		.react-datepicker-time__header,
		.react-datepicker__day,
		.react-datepicker__day-name {
			color: $white;
		}

		.react-datepicker__day:hover {
			color: $med-gray;
			border-radius: 100px;
		}

		.react-datepicker__day-name {
			width: 32px;
			margin: 0 4px;
		}

		.react-datepicker__month-container {
			padding: 28px;
		}

		.react-datepicker__day {
			width: $_32px;
			height: $_32px;
			margin: 4px;
			padding-top: 6px;
			padding-right: 1px;
			font-family: "Roboto", sans-serif;
			font-size: 14px;
			line-height: 20px;
			font-weight: normal;
			font-style: normal;
			outline: none;
		}

		.react-datepicker__day--disabled,
		.react-datepicker__day--outside-month {
			color: $gray;
		}

		.react-datepicker__day--today {
			background-color: transparent;
			border: 2px solid rgba(255, 255, 255, 0.4);
			border-radius: 100px;
		}

		.react-datepicker__day--selected,
		.react-datepicker__day--keyboard-selected {
			background-color: $red;
			border-color: transparent;
			border-radius: 100px;
			outline: none;
		}

		.react-datepicker__time-container {
			width: 120px;
			border-left: 2px solid $gray;

			.react-datepicker__time-box {
				width: 100%;

				.react-datepicker__time-list {
					color: $white;
					scrollbar-width: none;
					-ms-overflow-style: none;

					.react-datepicker__time-list-item:hover {
						color: $gray;
					}

					&::-webkit-scrollbar {
						display: none;
					}
				}
			}
		}
	}
}

.broadcast-time {
	width: 100%;

	.timepicker {
		@include radius-full;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 12px 16px;
		color: $white;
		background: transparent;
		border: 1px solid $border-med-gray;
		outline: none;
		transition: 150ms border;
		position: relative;

		.timepicker-input {
			width: 100%;
		}

		input[type="number"] {
			width: 90%;
			font-family: "Roboto", sans-serif;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
			background-color: transparent;
			border: none;
			-moz-appearance: textfield;

			&:active,
			&:focus {
				outline: none;
			}
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			margin: 0;
			-webkit-appearance: none;
		}

		&.entered {
			border: 1px solid $border-light-gray;
		}

		&.error {
			border: 1px solid $rose;
		}

		.error-msg,
		.info-msg {
			position: absolute;
			bottom: -20px;
			left: 18px;
			font-size: 12px;
		}

		.error-msg {
			color: $rose;
		}

		.info-msg {
			color: $border-light-gray;
		}
	}
}

.field-group {
	position: relative;

	label {
		display: inline-block;
		margin-bottom: 5px;
		margin-left: 16px;
		font-size: 12px;
		line-height: 16px;
		font-weight: 500;
		color: $border-light-gray;
	}

	.text-search-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		height: 32px;

		.text-search-input {
			padding-right: 30px !important;
		}

		.text-search-button {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			height: inherit;
			margin: 0;
			padding: 0;
			background-color: transparent;
		}
	}

	.tooltip {
		position: relative;
		display: inline-block;
		margin: 0 5px 5px;
		transform: translateY(-1px);

		.tooltip-text {
			position: absolute;
			left: 180%;
			z-index: 1;
			width: 260px;
			padding: 10px 15px;
			font-size: 12px;
			line-height: 18px;
			font-weight: 400;
			visibility: hidden;
			color: #fff;
			background-color: $gray;
			border-radius: 6px;
			opacity: 0;
			transform: translateX(-10px);
			transition:
				150ms opacity,
				150ms transform,
				150ms visibility 150ms;

			&::after {
				content: "";
				position: absolute;
				top: 13px;
				right: 100%;
				margin-top: -8px;
				border-width: 5px;
				border-style: solid;
				border-color: transparent $gray transparent transparent;
			}
		}

		&:hover {
			cursor: pointer;

			.tooltip-text {
				transition:
					150ms opacity,
					150ms transform;
				visibility: visible;
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	&.textarea {
		position: relative;
		margin-bottom: 24px;

		textarea {
			border-radius: 12px;
			display: flex;
			align-items: center;
			min-width: 100%;
			max-width: 100%;
			width: 100%;
			padding: 14px 16px;
			font-family: var(--headingFont);
			font-size: 14px;
			font-weight: 400;
			color: $white;
			background: transparent;
			border: 1px solid $border-med-gray;
			outline: none;
			transition: 150ms border;

			&::-webkit-resizer {
				display: none;
			}

			&:focus {
				border: 1px solid $border-light-gray;
			}

			&::-webkit-inner-spin-button {
				display: none;
			}

			&.square {
				background-color: $border-light-gray;
				border: none;
				border-radius: 0;
			}

			&:disabled {
				opacity: 0.5;
			}
		}

		.limit-indicator {
			position: absolute;
			right: 8px;
			bottom: 8px;
			font-size: 12px;
			color: $border-light-gray;
		}

		&.error {
			textarea {
				border: 1px solid $rose;
			}
		}
	}

	&.text {
		margin-bottom: 16px;
		position: relative;

		&.no-margin {
			margin: 0;
		}

		&.custom-api,
		&.intergation-bl-name {
			margin: 24px 0;
		}

		&.custom-url {
			margin-top: 24px;
			margin-bottom: unset;
		}

		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="password"] {
			border-radius: var(--customInputBorderRadius, 100px);

			&.isAdmin {
				border-radius: 100px;
			}

			display: flex;
			align-items: center;
			width: 100%;
			padding: 14px 16px;
			font-family: "Roboto",
			sans-serif;
			font-size: 14px;
			font-weight: 400;
			color: $white;
			background: transparent;
			border: 1px solid $border-light-gray;
			outline: none;
			transition: 150ms border;

			&:focus {
				border: 1px solid $border-light-gray;
			}

			&:-webkit-autofill,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus,
			&:-webkit-autofill:active {
				color: $white !important;
				background: $autocomplete !important;
				border: 1px solid $light-gray !important;
				// can't directly override chrome background color or text color so these are workarounds
				// -webkit-box-shadow: 0 0 0 30px red inset !important;
				box-shadow: 0 0 0 46px $autocomplete inset !important;
				-webkit-text-fill-color: $white !important;
				background-clip: content-box !important;
			}

			&::-webkit-inner-spin-button {
				display: none;
			}

			&.square {
				background-color: $border-light-gray;
				border: none;
				border-radius: 0;
			}

			&:disabled {
				opacity: 0.5;
			}

			&.has-icon {
				text-indent: 15px;
			}

			&.input-error {
				border-color: $red;
			}
		}

		.prefix {
			position: absolute;
			top: 36px;
			left: 16px;
			font-size: $_14px;
			color: $med-light-gray;

			&.has-tooltip {
				top: 44px;
			}
		}

		&.small {
			input {
				height: 32px;
			}

			.input-icon {
				position: absolute;
				top: 0;
				left: 12px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
			}
		}

		.input-icon {
			svg {
				path {
					transition: 150ms fill;
				}
			}
		}

		.clear-button {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 32px;
			background-color: transparent;
			opacity: 0;
			transition: 150ms opacity;
			pointer-events: none;
			@include flex-center;

			&.visible {
				opacity: 1;
				pointer-events: auto;
			}

			svg {
				path {
					transition: 150ms fill;
				}
			}

			&:hover {
				svg {
					path {
						fill: $gray-100;
					}
				}
			}
		}

		&.entered {

			input[type="text"],
			input[type="number"],
			input[type="email"],
			input[type="password"] {
				border: 1px solid $border-light-gray;
			}

			.input-icon {
				svg {
					path {
						fill: $white;
					}
				}
			}
		}

		&.ok {

			input[type="text"],
			input[type="number"],
			input[type="email"],
			input[type="password"] {
				border: 1px solid $green;
			}
		}

		&.warn {

			input[type="text"],
			input[type="number"],
			input[type="email"],
			input[type="password"] {
				border: 1px solid $rose;
			}
		}

		&.error {

			input[type="text"],
			input[type="number"],
			input[type="email"],
			input[type="password"] {
				border: 1px solid $rose;
			}
		}

		@keyframes flashInput {
			0% {
				box-shadow: 0 0 0 1px transparent;
			}

			20% {
				box-shadow: 0 0 0 1px $yellow;
			}

			100% {
				box-shadow: 0 0 0 1px transparent;
			}
		}

		&.flash {

			input[type="text"],
			input[type="number"],
			input[type="email"],
			input[type="password"] {
				animation: 500ms flashInput;
			}
		}

		label.error-message {
			color: $rose;
			position: fixed;
			left: inherit;
			top: inherit;
			margin-top: 4px;
		}

		.show-password {
			position: absolute;
			margin: 0;
			padding: 0;
			right: 6px;
			bottom: 0;
			height: calc(100% - 21px);
			width: 36px;
			@include flex-center;

			svg {
				path {
					transition: 150ms fill;
				}
			}

			&:hover {
				svg {
					path {
						fill: $gray-300;
					}
				}
			}
		}
	}

	&.select {
		select {
			@include radius-full;
			position: relative;
			display: flex;
			width: 100%;
			height: $_32px;
			padding: 0 16px;
			font-family: "Roboto", sans-serif;
			color: $white;
			background-color: transparent;
			border: 1px solid $border-med-gray;
			outline: none;
			appearance: none;
			cursor: pointer;

			option {
				padding: 0 16px;
			}

			&.large {
				height: $_48px;
			}
		}

		label {
			display: flex;

			.smart-tooltip {
				margin-left: 4px;
				top: 3px;
				position: relative;

				.smart-tooltip-content {
					position: fixed;
					bottom: inherit;
					left: inherit;
					margin-top: -48px;
					margin-left: -5px;
				}
			}

			&.error-message {
				color: $rose;
				position: fixed;
				top: inherit;
				left: inherit;
				margin-top: 4px;
			}
		}

		.select-container {
			@include radius-full;
			position: relative;
			display: flex;
			height: $_48px;
			border: 1px solid $border-med-gray;
			cursor: pointer;

			.main-icon {
				position: absolute;
				top: 0;
				right: 12px;
				bottom: 0;
				display: flex;
				align-items: center;
				transition: 0.2s;

				&.open {
					transform: rotateX(180deg);
				}
			}

			.selected-option {
				position: absolute;
				top: 0;
				left: 16px;
				bottom: 0;
				display: flex;
				align-items: center;
				font-size: 12px !important;
				font-weight: 500;

				&.placeholder {
					color: $border-light-gray;
				}

				&.clamp-selected-option {
					@include line-clamp(2);
					padding-right: 32px;
					margin: auto 0;
					height: fit-content;
				}
			}

			.select-dropdown-container {
				@include absolute-fill;
			}

			.select-dropdown {
				position: fixed;
				display: none;
				z-index: 999;

				&.open {
					display: inherit;
				}
			}

			.select-dropdown-container {
				&.use-relative-position {
					position: relative;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 5;

					.select-dropdown {
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}

			&.small {
				width: $_120px;
				height: $_32px;
				margin-right: $_8px;
			}

			&.error {
				border: 1px solid $rose;
			}
		}

		&.small {
			.select-container {
				height: $_32px;
			}
		}

		&.medium {
			.select-container {
				height: $_40px;
			}
		}

		&.disabled {
			.select-container {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	.tag-select,
	.enter-select {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: 560px;
		min-height: 46px;
		margin-bottom: 24px;
		padding: 0 6px 0 2px;
		border: 1px solid $border-med-gray;
		border-radius: 24px;
		max-height: 500px;
		overflow-y: auto;

		label {
			display: inline-block;
			margin-bottom: 5px;
			margin-left: 16px;
			font-size: 12px;
			line-height: 16px;
			font-weight: 700;
			color: $border-light-gray;
		}

		.tag {
			position: relative;
			@include radius-32;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 32px;
			margin: 6px 0 6px 6px;
			padding: 0 12px;
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
			color: $border-light-gray;
			background: $darker-gray;
			border: 2px solid $border-gray;
			cursor: pointer;
			/* stylelint-disable no-unknown-animations */
			animation: 150ms fadeIn;

			/* stylelint-enable no-unknown-animations */

			.edit-speaker-icon {
				display: none;
				position: absolute;
				top: -4px;
				right: -4px;
			}

			&:hover {
				.edit-speaker-icon {
					display: flex;
				}
			}

			button {
				margin: 0;
				padding: 0;
			}
		}

		input[type="text"] {
			min-width: 25%;
			flex: 1;
			color: $white;
			background: transparent;
			border: none;
			outline: none;
			padding: 14px 16px;
			display: flex;
			align-items: center;
		}

		&.error {
			border: 1px solid $rose;
		}

		.waiting-indicator {
			margin-left: 24px;
		}
	}

	&.tag-select-container {
		position: relative;

		.select-dropdown-container {
			@include absolute-fill;
			top: 60px;
		}

		.scrollable-tag-select {
			margin-bottom: 0;
			overflow-x: visible;
			overflow-y: auto;
			max-height: 108px;
			border-radius: 22px;
		}

		.tag-select-dropdown {
			position: fixed;
		}

		label.error-message {
			color: $rose;
			position: fixed;
			top: inherit;
			left: inherit;
			margin-top: -20px;
		}
	}

	&.searchable-select-container {
		.tag-select-dropdown {
			border-radius: 12px;
		}

		.searchable-select {
			span {
				font-size: 14px;
				position: relative;
				padding-left: 16px;
				padding-top: 0;

				&.placeholder {
					opacity: 0.5;
				}
			}

			.select-arrow {
				position: absolute;
				right: 10px;
				top: 32px;
				transform: rotate(-90deg);
			}
		}

		.tag-select-dropdown {
			overflow-y: hidden;

			.select-dropdown-scroll {
				overflow-y: auto;
			}

			.tag-option {
				height: 48px;

				img {
					margin-right: 8px;
				}

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}
		}

		.search-container {
			width: 100%;
			padding: 16px;

			.search-input {
				width: 100%;
				height: 36px;
				border-radius: 36px;
			}
		}
	}

	&.entered {
		.tag-select {
			border: 1px solid $border-light-gray;
		}
	}

	.tag-select-dropdown,
	.select-dropdown,
	.enter-select-dropdown {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 1;
		display: flex;
		//padding: 14px 16px;
		flex-direction: column;
		max-height: 51px * 3;
		margin-top: -16px;
		opacity: 0;
		overflow-y: auto;
		background-color: $med-gray;
		border-radius: 24px;
		box-shadow:
			0 2px 4px 2px rgba(0, 0, 0, 0.25),
			0 8px 24px rgba(0, 0, 0, 0.25);
		transform: translateY(-8px);
		transition:
			150ms opacity,
			150ms transform;
		pointer-events: none;

		&.pad {
			left: 40px;
			right: 40px;
		}

		&.noMaxHeight {
			max-height: none;
		}

		&.hide-empty-option {
			[data-value=""] {
				display: none;
			}
		}

		.tag-option,
		.select-option {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex: 1;
			padding: 16px;
			font-size: $_14px;
			cursor: pointer;

			span {
				color: $white;
			}

			.tag-left {
				display: flex;
				justify-content: center;
				align-items: center;

				.checkbox {
					margin-right: 16px;
				}
			}

			.tag-right {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 40px - 16px;
				opacity: 0;
				transition: 150ms opacity;

				span {
					margin-right: 16px;
					pointer-events: none;
				}

				&.is-default {
					opacity: 1;
				}
			}

			&:hover,
			&.show-hover {
				background-color: $border-gray;

				.tag-right {
					opacity: 1;
				}
			}

			&.active {
				background: $gray-500;
				cursor: default;

				&:hover {
					background: $gray-500;
				}
			}
		}

		&.open {
			opacity: 1;
			transform: translateY(0);
			pointer-events: auto;
		}

		.session-group {
			padding-left: 48px;
		}

		.session-group-item {
			display: flex;
			align-items: center;
			padding: 16px;

			label {
				margin: 0 0 0 16px;
				font-size: 14px;
				font-weight: 500;
				color: $white;
			}

			&.inner {
				margin-left: 48px;
			}
		}
	}

	&.session-select {
		.tag-select-dropdown {
			max-height: 51px * 5;
			background-color: $image-gray;
			cursor: pointer;
		}

		.placeholder {
			padding-bottom: 6px;
			padding-left: 8px;
			font-size: 14px;
			color: $border-light-gray;
			pointer-events: none;
		}

		&.scrollableParent {
			position: relative;

			.tag-select {
				transition: 150ms border;
			}

			&.error {
				.tag-select {
					border: 1px solid $rose;
				}
			}

			.tag-select-dropdown.session-select {
				left: 0;
				position: absolute;
				margin-left: 0;
				min-height: 52px;
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}

	&.checkbox {
		display: flex;
		align-items: center;
		border-radius: 4px;
		cursor: pointer;

		.defaultSpan {
			position: relative;
			display: block;
			width: 16px;
			height: 16px;
			background-color: $white;
			border-radius: 2px;

			svg {
				position: absolute;
				top: 1px;
				left: 1px;
				width: 14px;
				height: 14px;
				border-radius: 2px;

				path {
					fill: $dark-gray;
				}
			}
		}

		svg {
			border-radius: 3px;
			transition: 300ms background-color;
		}

		&.transparent {
			.defaultSpan {
				border: 1px solid $white;
				border-radius: 4px;
			}
		}

		&.cyan {
			background-color: $cyan;

			.defaultSpan {
				background-color: $cyan;
			}
		}

		&.has-label {
			display: inline-flex;
			align-items: center;
			width: fit-content;
			user-select: none;

			svg {
				margin-right: 5px;
			}
		}

		&.disabled {
			opacity: 0.5;
		}

		&.partial {
			>svg {
				// Partial icon is slightly larger for some reason
				transform: translate(1.5px, 1.5px);
			}
		}
	}

	&.radio-group {
		display: flex;
		margin-top: 32px;
		gap: 20px;

		&.add-intergation-radio {
			margin: 32px 0;
		}

		.allow-keyboard {
			&:focus-visible {
				outline: 1px solid auto;
			}
		}

		.radio-option {
			width: 100%;
		}
	}

	&.large-button {
		@include radius-16;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 72px;
		border: 1px dashed $border-med-gray;
		transition:
			150ms border-color,
			150ms background-color;
		cursor: pointer;

		&:hover {
			border-color: $border-light-gray;
		}

		&.has-label {
			margin-top: 20px;
		}

		.field-label-container {
			position: absolute;
			left: 15px;
			top: -28px;

			.field-label {
				margin: 0;
			}
		}

		label.inner {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: $cyan;
			pointer-events: none;
			max-width: 100%;
			padding: 0 20px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-family: $font-family-poppins;
			display: flex;
			align-items: center;

			svg {
				position: relative;
				margin-right: 3px;
			}

			&.error-message {
				color: $rose;
				position: absolute;
				bottom: -20px;
			}
		}

		&.has-subtitle {
			label {
				margin-top: 26px;
			}
		}

		span {
			display: inline-block;
			margin-top: 8px;
			margin-bottom: 26px;
			font-size: 14px;
			color: $border-light-gray;
			pointer-events: none;
		}

		&.dragging {
			background-color: rgba($cyan, 0.2);
			border-color: $cyan;
		}

		.uploading {
			@include absolute-fill;
			@include flex-center;
			@include radius-16;
			background-color: rgba($med-gray, 0.9);
		}

		&.error {
			border-color: $rose;
		}
	}

	&.switch-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.switch-right {
			display: flex;
			align-items: center;

			.custom-badge {
				@include radius-full;
				display: inline-block;
				margin-right: 8px;
				padding: 8px 16px;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				color: $med-light-gray;
				background-color: $darker-gray;
				border: 2px solid $border-gray;
			}

			button.options {
				display: none;
				justify-self: flex-end;
				margin: 0 16px 0 0;
				padding: 0;

				svg {
					path {
						transition:
							150ms fill,
							150ms stroke;
					}
				}

				&:hover {
					svg {
						path {
							fill: saturate(darken($cyan, 25%), 100);
							stroke: saturate(darken($cyan, 25%), 100);
						}
					}
				}
			}
		}

		label {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: $white;
		}
	}

	&.switch {
		display: flex;
		margin-left: 8px;

		&.right {
			margin-left: unset;
			margin-right: 8px;

			label {
				margin-left: unset;
				margin-right: 16px;
			}
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

.radio-option {
	display: flex;
	cursor: pointer;

	.radio {
		position: relative;
		width: 16px;
		height: 16px;
		border: 1px solid $white;
		border-radius: 100%;
		aspect-ratio: 1;

		span {
			position: absolute;
			top: 2px;
			left: 2px;
			display: block;
			width: 10px;
			height: 10px;
			background-color: $white;
			border-radius: 100%;
			transform: scale(0);
			transition: 100ms transform;
		}
	}

	label {
		margin: 0;
		margin-left: 8px;
		font-size: 14px;
		font-weight: normal;
		transition: color 100ms;
		cursor: pointer;
	}

	&.selected {
		.radio span {
			transform: scale(1);
		}

		label {
			color: $white;
		}
	}

	&:hover {
		label {
			color: $white;
		}
	}
}

.nav-switch {
	flex: 1;

	.hint {
		position: relative;
		top: -8px;
		margin-bottom: 8px;
		font-size: 12px;
		font-weight: 500;
	}
}

.editor {
	.field-group.large-button {
		border-color: var(--backgroundColorContrast, $cyan);
		filter: contrast(1);
		transition: 150ms filter;

		label.inner {
			color: var(--backgroundColorContrast, $cyan);
		}

		svg {
			path {
				fill: var(--backgroundColorContrast, $cyan);
			}
		}

		&:hover {
			filter: contrast(2);
		}
	}
}

.switch {
	height: 20px;
	cursor: pointer;
	transition: 150ms opacity;

	&:focus-visible .gutter {
		border: none;
		border-radius: 10px;
		box-shadow: 0 0 0 2px $cyan;
		outline: none;
	}

	&.inlineSwitch {
		display: inline-flex;
		align-items: center;
	}

	.gutter {
		@include radius-full;
		width: 34px;
		height: 16px;
		background-color: $border-light-gray;
		transition: 150ms background-color;
	}

	.thumb {
		position: relative;
		top: -2px;
		width: 20px;
		height: 20px;
		background-color: $white;
		border-radius: 100%;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		transform: translateX(0);
		transition:
			150ms transform,
			150ms background-color;
	}

	&:hover {
		.gutter {
			background-color: lighten($border-light-gray, 5%);
		}
	}

	&.on {
		.gutter {
			background-color: $green;
		}

		.thumb {
			transform: translateX(17px);
		}
	}

	&.isDisabled {
		opacity: 0.3;
		cursor: default;

		&.on {
			.gutter {
				background-color: $alt-gray;
			}

			.thumb {
				background-color: $alt-gray;
			}
		}
	}

	&.paused {
		opacity: 0.5;
		pointer-events: none;
	}
}

.pill-toggle {
	display: flex;

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	button {
		width: 50%;
		height: 32px;
		padding: 0;
		background-color: transparent;
		border: 1px solid $border-gray;

		&.selected {
			background-color: $border-gray;
		}

		&:first-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:last-child {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}


.pill-toggle-round {
	width: 100%;
	display: flex;
	background: $med-gray;
	border-radius: 100px;
	position: relative;

	.slider {
		position: absolute;
		width: 50%;
		height: 40px;
		border-radius: 100px;
		border: 3px solid #26242B;
		transition: left 0.15s ease-in-out;
		background-color: $border-gray;
		z-index: 0;

		&.position-1 {
			left: 0;
		}

		&.position-0 {
			left: 50%;
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	button {
		width: 50%;
		height: 40px;
		padding: 0;
		background-color: transparent;
		z-index: 1;
	}
}

[contenteditable][placeholder]:empty::before {
	content: attr(placeholder);
	position: absolute;
	color: $gray;
	background-color: transparent;
}

.clear-fieldset {
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-block-start: 0;
	padding-block-end: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	border: none;
	min-inline-size: min-content;
}
