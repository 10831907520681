@import "./src/scss/old-deps";
@import "./src/scss/deps";
@import "./src/scss/base/colors";
@import "./src/scss/abstracts/colors";

$chat-avatar-width: 32px;
$chat-avatar-height: 32px;

.live-chat-container {
	min-height: 100%;
	max-height: 100%;
	width: 432px;
	display: flex;
	flex-direction: column;
	color: var(--user-mode-color);
	border-radius: 0;
	border-left: 1px solid $above-the-fold-outline-color;
	height: 100vh;
	height: 100svh;

	&.is-editor {
		pointer-events: none;
		height: 0 !important;

		.feed-container,
		.chat-actions-container {
			display: none;
		}
	}

	.live-chat-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $border-gray;
		padding: 16px 16px 20px;

		* {
			margin: 0;
			padding: 0;
		}

		h5 {
			color: $dark-gray;
		}

		button {
			svg {
				path {
					fill: $dark-gray;
				}
			}
		}

		&.dark-mode {
			h5 {
				color: #ffffff;
			}

			button {
				svg {
					path {
						fill: #bfbbcf;
					}
				}
			}
		}

		&.dark-mode {
			border-bottom: 1px solid $border-gray;
		}
	}

	.chat-actions-container .add-live-comment-textarea {
		background-color: $dark-gray;
		border: 1px solid $border-lightest-gray;
		color: #908ca0;
		transition: border-color 150ms;

		&:focus-within {
			border-color: rgba(0, 0, 0, 0.2);
		}

		&::placeholder {
			color: $border-lightest-gray;
		}
	}

	.chat-actions-container.dark-mode {
		.add-comment-container .add-comment .add-live-comment-textarea {
			background-color: $dark-gray;
			border: 1px solid $border-gray;
			color: #ffffff;

			&:focus-within {
				border-color: $gray;
			}

			&::placeholder {
				color: #908ca0;
			}
		}

		.comment-actions {
			button {
				svg {
					path {
						fill: #908ca0;
					}
				}
			}
		}
	}

	.live-chat-header-mobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 16px 16px 20px;
		cursor: pointer;

		button {
			height: 38px;
			width: 38px;
			padding: 0;
			margin: 0;
			margin-top: -8px;
			margin-bottom: -8px;
			margin-right: -8px;
		}

		* {
			margin: 0;
			padding: 0;
		}

		h6 {
			font-weight: normal;
		}
	}

	.feed-container {
		height: 100%;
		overflow-y: hidden;
		flex: 1;
		opacity: 0;
		transition: opacity 300ms 300ms;

		.messages-above {
			height: 75px;
			background: linear-gradient(180deg, var(--backgroundColor), rgba(0, 0, 0, 0));
			position: absolute;
			top: 0;
			left: 1px;
			right: 0;
			opacity: 0;
			transition: opacity 300ms;
			pointer-events: none;
		}

		.messages-below {
			height: 75px;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0), var(--backgroundColor));
			position: absolute;
			bottom: 60px;
			left: 1px;
			right: 0;
			opacity: 0;
			transition: opacity 300ms;
			pointer-events: none;
		}

		.show-fade {
			opacity: 1;
		}

		&:not(.at-top) {
			.messages-above {
				opacity: 1;
			}
		}

		&:not(.at-bottom) {
			.messages-below {
				opacity: 1;
			}
		}

		.messages-below.hide-fade,
		.messages-above.hide-fade {
			opacity: 0;
		}

		.comment-feed-container {
			flex: 1;
			width: 100%;
			padding: 0 16px 0 16px;
			overflow-x: hidden;
			height: 100%;

			.comment-feed-virtuoso {
				height: 100%;
			}

			.chat-comment {
				display: flex;
				padding-top: 4px;
				padding-bottom: 4px;
				position: relative;

				&.high-volume-style {
					padding-bottom: 6px;
					padding-top: 6px;

					.chat-comment {
						padding: 0;
					}

					.chat-avatar {
						height: 20px;
						min-height: 20px;
						width: 20px;
						min-width: 20px;
						font-size: 8px;
					}

					.chat-comment-inner {
						background: none;
						background-color: transparent !important;
						padding: 0;
						font-size: 12px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						white-space: nowrap;

						&.dark-mode {
							color: $white;

							.chat-comment {
								.chat-commenter-name {
									color: rgba(var(--user-mode-color-rgb-partial), 0.6);
								}
							}
						}

						.chat-comment {
							margin-bottom: 0px;
							display: inline-block;

							.chat-commenter-name {
								color: rgba(var(--user-mode-color-rgb-partial), 0.6);
								word-break: break-all;
								font-weight: 600;
							}

							.chat-comment-content {
								line-height: 20px;
								font-weight: 500;
								word-break: break-word;
							}
						}

						.lightbox-trigger-btn {
							margin-top: 6px;

							>.lightbox-default-img {
								height: 168px;
								width: fit-content;
								max-width: 367px;
								margin-bottom: 0px;
							}
						}
					}

					&:hover {
						.chat-comment-date {
							opacity: 1;
							transition: 150ms opacity;
						}
					}

					.chat-comment-date {
						--date-bg-color: var(--backgroundColor);
						&.dark-mode {
							--date-bg-color: var(--modeBackgroundColor);
						}
						font-size: 11px;
						position: absolute;
						right: 0;
						top: 6px;
						opacity: 0;
						transition: 450ms opacity;
						padding: 0 8px 0 16px;
						color: rgba(var(--user-mode-color-rgb-partial), 0.6);
						display: flex;
						flex-direction: column;
						align-items: center;
						height: fit-content;
						padding-bottom: 6px;
						background: linear-gradient(to right, transparent, var(--date-bg-color) 16%);
					}

					&.user-continued {
						padding-top: 6px;
						padding-bottom: 6px;

						.chat-avatar {
							opacity: 1;
						}
					}

					&.first-comment {
						.chat-comment-date {
							top: 20px; // accounts for padding top on first comment
						}
					}
				}

				.chat-comment-inner {
					.jumbo-font {
						font-size: 38px;
					}

					@include radius-16;
					padding: 12px;
					font-size: 14px;
					max-width: calc(100% - #{$chat-avatar-width});
					background: rgba(255, 255, 255, 0.1);

					img {
						margin-bottom: 8px;
						height: 160px;
						width: 160px;
						object-fit: contain;
						background-color: black;
					}
				}

				.chat-comment-meta {
					margin-bottom: 8px;

					&.hidden {
						display: none;
					}

					.chat-comment-name {
						font-weight: 500;
						margin-right: 8px;

						&.moderator {
							font-weight: 900;
						}
					}
				}

				.chat-comment-content {
					white-space: pre-wrap; // preserves line breaks
					max-width: 223px;
					max-height: 960px; // height to fit a legitimate 1000 characters and not 1000 new lines
					overflow: hidden;
					text-overflow: ellipsis;
					overflow-wrap: break-word;
				}

				&.user-continued {
					padding-top: 2px;
					padding-bottom: 2px;

					.chat-avatar {
						opacity: 0;
					}

					.chat-comment-meta {
						display: none;
					}
				}
			}
		}

		.show-more-button {
			position: sticky;
			bottom: 62px;
			left: 0;
			margin: 0 auto;
			display: none;

			&.visible {
				display: block;
			}
		}

		&.dark-mode {
			.comment-feed-container .chat-comment .chat-avatar {
				background-color: var(--user-mode-color);
				color: var(--modeBackgroundColor);

				&.moderator {
					font-weight: 900;
				}
			}

			.messages-above {
				background: linear-gradient(180deg, $dark-gray, rgba(0, 0, 0, 0));
			}

			.messages-below {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0), $dark-gray);
			}
		}
	}

	.add-comment-container {
		padding: 16px;
		position: relative;

		.spamFilterAlert {
			position: absolute;
			display: none;
			bottom: 100%;
			left: 0;
			border: 1px solid $rose;
			background-color: $rose;

			.spamFilertAlertContent {
				position: relative;
				padding: 5px;

				button {
					position: absolute;
					top: 0;
					right: 0;
				}
			}

			&.showSpamFilterAlert {
				display: block;
			}
		}

		.gif-preview-container,
		.image-preview-container {
			background-color: rgba(var(--bodyTextColor-rgb), 0.9);
			@include radius-16;
			padding: 16px;
			font-size: 14px;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 8px;
			border-radius: 0;

			button {
				margin: 0;
				padding: 0;
			}

			img {
				height: 100px;
			}
		}

		.add-comment {
			display: flex;
			position: relative;
			flex: 1;

			.add-live-comment-textarea {
				width: 100%;
				color: $dark-gray;
				resize: none;
				padding: 6px 90px 6px 16px;
				border-radius: var(--customInputBorderRadius, 32px);
				line-height: 20px;
				font-size: 14px;
				height: 32px;
				font-family: var(--bodyFont);

				&:focus {
					outline: none;
				}
			}

			.comment-character-limit {
				font-size: 14px;
				position: absolute;
				bottom: 17px;
				right: 15px;
			}

			.submitting-chat-message {
				color: $gray-300;
			}

			.comment-actions {
				display: flex;
				align-items: center;
				height: 100%;
				position: absolute;
				right: 0;
				padding: 0 8px 0 0;

				#send-button {
					display: none;
				}

				button {
					&:before {
						background: transparent;
					}

					svg {
						path {
							fill: #bfbbcf;
						}
					}

					transition: scale 150ms;

					&:hover {
						scale: 1.3;
					}
				}
			}
		}
	}

	.popover-wrapper {
		max-height: none;
		width: fit-content;
		bottom: 100%;
		right: 0;
		left: auto;
	}

	// start giphy styles
	.giphy-picker-container {
		position: relative;

		.popover-wrapper {
			background: none;
		}

		.reactGiphySearchbox-componentWrapper {
			--mart-dark-background: var(--backgroundColor);
			background: var(--mart-dark-background);
			padding: 24px;
			color: inherit;
			border-radius: 12px;
			width: 262px !important; // need to overwrite width to allow padding

			::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			.reactGiphySearchbox-searchForm-input {
				--giphy-search-border-color: $border-lightest-gray;
				--giphy-search-background: var(--containerColor);

				border: 1px solid $border-lightest-gray;
				background-color: var(--giphy-search-background);
				padding: 0.5em 0.75em;
				box-sizing: border-box;
				font-family: inherit;
				font-size: 1em;
				border-radius: 8px;
				color: var(--user-mode-color);

				&:focus {
					border: 1px solid rgba(var(--containerColor-rgb), 0.6);
					outline: none;
				}
			}
		}
	}

	.emoji-picker-container {
		position: relative;

		.popover-wrapper {
			max-height: none;
			width: fit-content;
			bottom: 100%;
		}

		@include mobile {
			display: none;
		}
	}

	.emoji-mart {
		// reverse the display
		display: flex;
		flex-direction: column-reverse;

		padding: 20px 0 0;
		--mart-dark-background: 59, 58, 65;

		background: var(--backgroundColor);
		border-color: var(--backgroundColor);

		.emoji-mart-bar {
			background: var(--backgroundColor);
			border-color: var(--backgroundColor);
		}

		.emoji-mart-category span {
			background: var(--backgroundColor);
			color: $dark-blue;
		}

		.emoji-mart-search {
			input {
				color: $dark-blue;
				border-color: $border-lightest-gray;
				background-color: var(--containerColor);
			}
		}

		.emoji-mart-anchors {
			margin: 16px 0;

			.emoji-mart-anchor-icon {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.emoji-mart-anchor {
				padding: 9px;
				border-radius: 36px;

				svg {
					color: #fff;
				}
			}

			.emoji-mart-anchor-selected {
				background-color: rgba(var(--containerColor-rgb), 0.3);
				color: #fff;
			}
		}

		.emoji-mart-anchor-bar {
			display: none;
		}

		.emoji-mart-anchor-icon svg {
			margin: 0 auto;
		}

		// completely remove emoji picker preview
		.emoji-mart-preview {
			display: none;
		}
	}

	&.Limelight {

		.giphy-picker-container {
			.popover-wrapper {
				margin-bottom: 30px;

				&.open {
					width: 399px;
					right: -100px;

					@include smallDesktop {
						width: 350px;
					}

					.reactGiphySearchbox-componentWrapper {
						width: 399px !important;

						@include smallDesktop {
							width: 350px !important;
						}
					}
				}
			}

			@include mobile {
				.popover-wrapper.open {
					right: 20px;
					position: fixed;
					left: 20px;
					bottom: 40px;
					width: calc(100vw - 54px); // idk why it's 54px but it works

					.reactGiphySearchbox-componentWrapper {
						width: calc(100vw - 54px) !important;
					}
				}
			}
		}

		.emoji-picker-container {
			.popover-wrapper {
				margin-bottom: 30px;

				&.open {
					width: 399px;
					right: -80px;

					@include smallDesktop {
						width: 350px;
					}

					.emoji-mart {
						width: 399px !important;

						@include smallDesktop {
							width: 350px !important;

							.emoji-mart-scroll {
								height: 200px;
							}
						}
					}
				}
			}
		}
	}

	.dark-mode {
		.reactGiphySearchbox-componentWrapper {
			--gif-dark-background: 59, 58, 65;
			background: rgba(var(--gif-dark-background), 0.9);

			.reactGiphySearchbox-searchForm-input {
				--giphy-search-border-color: #555453;
				--giphy-search-background: #2f2f2f;

				border-color: var(--giphy-search-border-color);
				background-color: var(--giphy-search-background);
			}
		}

		.emoji-mart {
			--mart-dark-background: 59, 58, 65;

			background: rgba(var(--mart-dark-background), 1);
			border-color: rgba(var(--mart-dark-background), 1);

			.emoji-mart-bar {
				border-color: rgba(var(--mart-dark-background), 0.9);
			}

			.emoji-mart-category-label {
				span {
					background-color: rgba(var(--mart-dark-background), 0.9);
					color: var(--user-mode-color);
				}
			}

			.emoji-mart-anchors {
				.emoji-mart-anchor {
					svg {
						color: var(--user-mode-color);
					}
				}

				.emoji-mart-anchor-selected {
					background-color: rgba(var(--containerColor-rgb), 0.3);
					color: var(--user-mode-color);
				}
			}

			.emoji-mart-bar {
				background: rgba(var(--mart-dark-background), 0.9);
				border-color: rgba(var(--mart-dark-background), 0.9);
			}

			.emoji-mart-category span {
				background: rgba(var(--mart-dark-background), 0.9);
			}

			.emoji-mart-search input {
				--emoji-search-border-color: #555453;
				--emoji-search-background: #2f2f2f;

				--mart-dark-background: 59, 58, 65;
				background-color: var(--emoji-search-background);
				border-color: var(--emoji-search-border-color);
				color: var(--user-mode-color);
			}
		}
	}

	// end giphy styles

	&.chat-overlay {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0px;
		width: 326px;
		background: none;
		min-height: calc(100% - 90px);
		border-left: none;

		.live-chat-header {
			display: none;
		}

		.feed-container {
			top: 0;
			right: 0;
			bottom: 70px;
			// height: initial;

			.comment-feed-container {
				overflow: hidden;
				position: initial;
			}
		}
	}
}

.live-event-container,
.editor {

	.session,
	.editor-wrapper-right {
		.session-stream.version-2 {
			.live-chat-wrapper.chat-overlay {
				bottom: 80px;
				min-height: 100%;
				top: unset;

				.live-chat-container {
					width: 350px;
					margin-right: 16px;

					.feed-container {
						.comment-feed-container {
							.chat-comment {
								flex: 1;
								max-width: 100%;

								.chat-comment-date {
									background-color: transparent;
									color: var(--user-mode-color);
								}

								.chat-comment-inner {
									background-color: var(--containerColor);
									border-radius: 12px;

									.chat-comment-meta {
										margin-bottom: 4px;
										color: var(--grayscale-300-grey, #bfbbcf);
										font-size: 13px;
										font-style: normal;
										font-weight: 500;
										line-height: 20px;
										/* 153.846% */

										.chat-comment-date {
											font-weight: 400;
										}
									}

									img {
										border-radius: 4px;
									}

									.chat-comment-content {
										font-size: 13px;
										line-height: 20px;

										&.jumbo-font {
											font-size: 38px;
											line-height: unset;
										}
									}

									&.dark-mode {
										background-color: $med-gray;
									}
								}

								&.user-continued {
									padding-bottom: 8px;
								}
							}
						}
					}

					.chat-actions-container {
						width: 350px;
						padding: 0;

						.add-comment-container {
							padding: 0;
						}
					}
				}
			}

			.session-video-placeholder.version-2 {
				+.live-chat-wrapper.chat-overlay {
					bottom: 20px;

					.chat-actions-container .add-comment-container {

						.gif-preview-container,
						.image-preview-container {
							margin-top: -5px;
							max-width: calc(100% - 28px);

							img {
								max-width: calc(100% - 35px);
							}
						}
					}
				}
			}

			&.is-fullscreen {
				.live-chat-wrapper.chat-overlay {
					margin-top: 0;
				}
			}
		}

		.live-chat-container .feed-container .comment-feed-container .chat-comment.high-volume-style .chat-comment-date {
			background-color: transparent;
			color: var(--user-mode-color);
		}

	}
}

@mixin liveChatMobile($size) {
	@if $size =="desktop" {
		.live-chat-wrapper {
			width: 0;
			transform: translateX(143px);
			opacity: 0;
			transition:
				opacity 60ms,
				transform 150ms,
				width 150ms;
			min-height: 100%;
			height: 1px;

			&.chat-overlay {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 90px;
				width: 0px;
				min-height: calc(100% - 90px);
				padding: 24px;
				transform: initial;
				transform: translateX(275px);
				opacity: 0;
				pointer-events: none;

				.feed-container {
					opacity: 1;
					width: 100%;
				}

				.live-chat-container {
					width: calc(350px - 24px);

					.feed-container:not(.pre-live) {
						padding-top: 20px;
					}

					.chat-actions-container {
						width: calc(350px - 24px);
						padding: 0 24px;

						.add-comment-container {
							padding: 0 0 0 34px;
						}
					}
				}
			}

			&.active {
				transition:
					opacity 150ms 50ms,
					transform 150ms,
					width 150ms;
				transform: translateX(0);
				opacity: 1;
				width: 432px;

				.feed-container {
					opacity: 1;
					width: 100%;
				}

				&.chat-overlay {
					pointer-events: auto;
				}
			}
		}

		.is-fullscreen {
			.live-chat-wrapper {
				&.chat-overlay {
					margin-top: 70px;

					.live-chat-container {
						.feed-container {
							padding-top: 20px;
						}
					}
				}
			}
		}

		.version-2 {
			.live-chat-wrapper {
				&:not(.chat-overlay) {
					background-color: var(--backgroundColor);

					&.dark-mode {
						background-color: #1a1a1f;
					}
				}
			}
		}
	}

	@if $size=="small-desktop" {
		.live-chat-wrapper {
			width: 0;
			transform: translateX(143px);
			opacity: 0;
			transition:
				opacity 60ms,
				transform 150ms,
				width 150ms;
			min-height: 100%;

			&.chat-overlay {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 90px;
				width: 0px;
				min-height: calc(100% - 90px);
				padding: 24px;
				transform: initial;
				transform: translateX(275px);
				opacity: 0;
				pointer-events: none;

				.feed-container {
					opacity: 1;
					width: 100%;
				}

				.live-chat-container {
					width: calc(350px - 24px);

					.messages-above {
						display: none;
					}

					.feed-container {
						// padding-top: 90px;

						&.fireside-session {
							&.pre-live {
								margin-top: 90px;
								padding-top: 0;
							}
						}
					}

					.chat-actions-container {
						width: calc(350px - 24px);
						padding: 0 24px;

						.add-comment-container {
							padding: 0 0 0 34px;
						}
					}
				}
			}

			&.active {
				transition:
					opacity 150ms 50ms,
					transform 150ms,
					width 150ms;
				transform: translateX(0);
				opacity: 1;
				width: 286px;

				.feed-container {
					opacity: 1;
					width: 100%;
				}

				&.chat-overlay {
					pointer-events: auto;
				}
			}
		}

		.live-chat-container {

			@include media-query($sm-up) {

				// fireside-tablet styling is matching mobile styling so if something changes there it should change here too
				&.chat-as-bar {
					width: 100%;
					color: var(--bodyTextColor);
					margin-left: 0;
					background-color: var(--containerColor);
					position: sticky;
					margin-top: -15px;
					left: 0;
					right: 0;
					top: auto;
					height: 60px;
					min-height: 60px;
					overflow: hidden;
					transition: 150ms all;
					z-index: 3;

					&.Limelight {
						margin-top: 0;
						height: 50px;
						min-height: 50px;
						background-color: var(--user-mode-background);
					}

					&.is-editor {
						top: 330px;
					}

					.feed-container {
						opacity: 1;
						height: calc(100% - 66px - 56px);

						.comment-feed-container {
							.chat-comment {
								.chat-comment-inner {
									background: var(--backgroundColor);
								}
							}
						}
					}

					&:not(.Limelight) {
						.add-comment-container {

							.gif-preview-container,
							.image-preview-container {
								position: fixed;
								margin-top: -150px;
								left: 20px;
								right: 20px;
								background: var(--backgroundColor);

								svg {
									path {
										fill: var(--bodyTextColor) !important;
									}
								}
							}

							.add-comment {
								svg {
									path {
										fill: var(--bodyTextColor) !important;
									}
								}

								.add-live-comment-textarea {
									background: var(--backgroundColor);
									color: var(--bodyTextColor);
									padding: 8px 85px 8px 16px;
									line-height: 22px;
									font-size: 16px;
									height: 36px;
								}

								.comment-actions {
									button {
										height: 100%;
										padding: 0;
										width: 36px;
									}

									#send-button {
										display: flex;
									}

									#image-button {
										display: none;
									}
								}
							}

							.popover {
								background: var(--backgroundColor);
							}

							.emoji-mart,
							.emoji-mart-bar,
							.giphy-picker-container .reactGiphySearchbox-componentWrapper {
								background: var(--backgroundColor);
								border-color: var(--backgroundColor);

								input,
								.emoji-mart-category-label span {
									background: var(--backgroundColor);
									border-color: var(--backgroundColor);
									color: var(--bodyTextColor);
								}
							}
						}
					}

					&.is-open {
						.add-comment-container {
							position: fixed;
							background-color: var(--containerColor);
							z-index: 4;
							bottom: 0;
							width: 100%;
						}
					}

					&.move-chat-up {
						z-index: 102;
					}
				}
			}
		}
	}

	@if $size =="mobile" {
		.live-chat-wrapper {
			width: initial;
			transform: initial;
			opacity: initial;
			min-height: initial;
			z-index: 2;
			position: relative;

			&.active {
				width: initial;
			}

			&.chat-overlay {
				min-height: initial;
				opacity: initial;
				transform: initial;
				padding: initial;
				pointer-events: initial;
				width: 100%;
				position: fixed;
			}
		}

		// fireside-tablet styling above is matching mobile styling so if something changes here it should change there too
		.live-event-container .session .session-main-content .live-chat-container,
		.live-chat-container {
			// there's a weird 1 pixel offset on the right side of the chat on mobile
			// so we're going to force the div to be 5px wider than the screen
			width: calc(100% + 10px);
			margin-left: 0;
			position: fixed;
			left: 0;
			right: 0;
			top: auto;
			height: var(--mobile-chat-height);
			min-height: var(--mobile-chat-height);
			overflow: hidden;
			transition: 150ms all;
			z-index: 6;
			margin-left: -5px;
			margin-right: -5px;
			padding-left: 5px;
			padding-right: 5px;

			&.fireside-session {
				margin-top: 0;

				// without doing a big dynamic JS restructure of how we're setting the 56% padding on .session-stream
				// this is the easiest quick solution to prevent chat from hiding under the stream when resizing browser
				@media screen and (max-width: 640px) {
					margin-top: 0;
				}

				@media screen and (max-width: 530px) {
					margin-top: 0;
				}

				&.is-editor {
					margin-top: 0;
				}
			}

			&.is-editor {
				top: calc(330px + var(--banner-height, 0px));

				&.video-v2 {
					top: calc(211px + var(--banner-height, 0px));
				}
			}

			.feed-container {
				opacity: 1;
				height: calc(100% - 66px - 56px - var(--banner-height, 0px));
				mask-image: linear-gradient(#00000000, #ffffff 5%);

				.messages-above {
					display: none;
				}
			}

			&:not(.Limelight) {
				color: var(--bodyTextColor);
				background-color: var(--containerColor);
				margin-top: calc(-5px + (-1 * var(--mobile-chat-height)));

				.feed-container {
					opacity: 1;
					height: calc(100% - 66px - 56px - var(--banner-height, 0px));
					mask-image: linear-gradient(#00000000, #ffffff 5%);

					.messages-above {
						display: none;
					}

					.comment-feed-container {
						.chat-comment {

							.chat-comment-inner,
							.chat-comment-inner.dark-mode {
								background: var(--backgroundColor);

								.chat-comment-meta {
									color: $dark-gray;
								}

								.chat-comment-content {
									color: $dark-gray;
								}
							}

							.chat-avatar {
								background-color: $dark-gray;
								color: #ffffff;

								&.moderator {
									font-weight: 900;
								}
							}
						}
					}

					&.dark-mode {
						.comment-feed-container .chat-comment .chat-avatar {
							background-color: $dark-gray;
							color: #ffffff;

							&.moderator {
								font-weight: 900;
							}
						}
					}
				}

				.add-comment-container {

					.gif-preview-container,
					.image-preview-container {
						position: fixed;
						margin-top: -150px;
						left: 20px;
						right: 20px;
						background: var(--backgroundColor);

						svg {
							path {
								fill: var(--bodyTextColor) !important;
							}
						}
					}

					.add-comment {
						svg {
							path {
								fill: var(--bodyTextColor) !important;
							}
						}

						.add-live-comment-textarea {
							background: var(--backgroundColor);
							color: var(--bodyTextColor);
							padding: 8px 85px 8px 16px;
							line-height: 22px;
							font-size: 16px;
							height: 36px;
						}

						.comment-actions {
							button {
								height: 100%;
								padding: 0;
								width: 36px;
							}

							#send-button {
								display: flex;
							}

							#image-button {
								display: none;
							}
						}
					}

					.popover {
						background: var(--backgroundColor);
					}

					.emoji-mart,
					.emoji-mart-bar,
					.giphy-picker-container .reactGiphySearchbox-componentWrapper {
						background: var(--backgroundColor);
						border-color: var(--backgroundColor);

						input,
						.emoji-mart-category-label span {
							background: var(--backgroundColor);
							border-color: var(--backgroundColor);
							color: var(--bodyTextColor);
						}
					}
				}


			}

			&.is-open {
				&--mobile {
					position: "absolute";
					max-height: "none";
				}

				.add-comment-container {
					position: fixed;
					background-color: var(--containerColor);
					z-index: 4;
					bottom: 0;
					width: 100%;
				}
			}
		}

		// remove margin top on mobile chat so we don't get a weird gap
		.live-event-container .session.pre-live .session-main-content .live-chat-container.is-open--mobile.is-ios {
			margin-top: 0;
		}
	}

	.live-chat-promotions-container {
		background-color: var(--bodyTextColor);
		padding: 12px;
		margin: 0 12px 10px;
		border-radius: 12px;
		display: flex;
		color: var(--backgroundColor);

		@if $size=="small-desktop" {
			margin-left: 58px;
			margin-right: 24px;
			margin-bottom: 8px;

			&.fireside-session {
				margin-top: 2px; // prevent raise hand card from peeking out of chat
			}
		}

		.icon-container {
			background-color: #3c3a42;
			border-radius: 12px;
			width: 35px;
			height: 35px;
			margin-right: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.message {
			flex: 1;
			font-weight: bold;
		}

		.action-button {
			margin-top: 12px;
			width: 100%;

			svg {
				margin-right: 5px;
			}
		}

		.dismiss {
			margin-left: 12px;
			height: fit-content;

			&:hover {
				cursor: pointer;
			}
		}

		&.fireside-session {
			.message {
				button {
					white-space: nowrap;
				}
			}
		}
	}
}

// must be root level, because it's in a modal portal
.non-registered-modal-actions {
	display: flex;
	justify-content: flex-end;

	button {
		margin-left: 8px;
	}
}

@include media-query($lrg-up) {
	.live-event-container {
		@include liveChatMobile(("desktop"));
	}
}

@include media-query($med-down) {
	.live-event-container {
		@include liveChatMobile("small-desktop");
	}
}

@include media-query($sm-down) {
	.live-event-container {
		@include liveChatMobile("tablet");
	}
}

@include media-query($xs-down) {
	.live-event-container {
		@include liveChatMobile("mobile");
	}
}

.editor.desktop {
	@include liveChatMobile("desktop");
}

.editor.mobile {
	@include liveChatMobile("mobile");
}

.editor.tablet {
	@include liveChatMobile("small-desktop");
	@include liveChatMobile("tablet");
}
