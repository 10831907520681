@import './src/scss/old-deps';
@import './src/scss/deps';

@keyframes blip {

	0% {
		// transform: scale(1);
		fill: transparent;
		r: 2.5;
	}

	20% {
		// transform: scale(1.4);
		fill: var(--gray-100);
		r: 4;
	}

	100% {
		// transform: scale(1);
		fill: transparent;
		r: 2.5;
	}
}

@keyframes blip-dark {

	0% {
		// transform: scale(1);
		fill: transparent;
		r: 2.5;
	}

	20% {
		// transform: scale(1.4);
		fill: var(--gray-1000);
		r: 4;
	}

	100% {
		// transform: scale(1);
		fill: transparent;
		r: 2.5;
	}
}

.waiting-indicator-fill-space {
	@include flex-center;
	height: 100%;
	width: 100%;
	background-color: var(--gray-1000);
}

.waiting-indicator {

	circle {
		transform-origin: center center;
	}

	.circle-1,
	.circle-2,
	.circle-3 {
		animation-iteration-count: infinite;
		animation-duration: 1.4s;
	}

	&:not(.dark-loader) {
		.circle-1,
		.circle-2,
		.circle-3 {
			animation-name: blip;
		}
	}
	&.dark-loader {
		.circle-1,
		.circle-2,
		.circle-3 {
			animation-name: blip-dark;
		}
	}

	.circle-1 {
		animation-delay: 0s;
	}

	.circle-2 {
		animation-delay: 0.3s;
	}

	.circle-3 {
		animation-delay: 0.6s;
	}
}
